import { type FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Box,
  BoxProps,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Grid,
  Icon,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";

import { euroValueCurrency } from "src/constants";
import { BookingType, ContractBooking } from "src/types/contract";
import { calculateCurrentContractStockPriceValue } from "src/utils/calculateCommodityValue";
import { useUser } from "src/services/contexts/User/useUser";
import { useCommodityListForContractForCustomerQuery } from "src/services/serverState/commodity/useCommodityListForContractForCustomerQuery";
import useCommodityBookings from "src/services/serverState/commodityBookings/useCommodityBookings";
import { useContractForCustomerQuery } from "src/services/serverState/contract/useContractForCustomerQuery";
import { useContractListForCustomerQuery } from "src/services/serverState/contract/useContractListForCustomerQuery";
import { contractBookingKeys } from "src/services/serverState/contractBooking/key";
import { useInfiniteContractsBookingListForContractForCustomerQuery } from "src/services/serverState/contractBooking/useContractsBookingListForContractForCustomerQuery";
import {
  PaginatedQueryCache,
  queryClient,
} from "src/services/serverState/queryClient";
import { useStockPriceListQuery } from "src/services/serverState/stockPrices/useStockPriceListQuery";
import WidgetActionTabs from "src/components/ActionTabs/ActionTabs";
import { CommodityCard } from "src/components/CommodityCard/CommodityCard";
import { ContractBookingList } from "src/components/ContractBookingList/ContractBookingList";
import { TwoSectionsCard } from "src/components/DataCards/TwoSectionsCard/TwoSectionsCard";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip";
import LayoutContentHeader from "src/components/LayoutContentHeader/LayoutContentHeader";
import { Seo } from "src/components/Seo";

import { useStyles } from "./Customer.ContractDetails.styles";

interface ContractDetailPageProps extends BoxProps {}

enum ContractDetailTabChoice {
  COMMODITIES = "commodities",
  DETAILS = "details",
}

export const ContractDetailPage: FC<ContractDetailPageProps> = ({
  ...props
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { userState } = useUser();
  const { contractNumber } = useParams();

  const {
    bookingsPerCommodity,
    fetchCommodityBookings,
    resetBookingsPerCommodity,
  } = useCommodityBookings();

  const [filterContractBookings, setFilterContractBookings] = useState("");
  const [selectedTab, setSelectedTab] = useState(
    ContractDetailTabChoice.DETAILS,
  );
  const [selectedCommodity, setSelectedCommodity] = useState<
    { commodity: string; unit: string } | undefined
  >(undefined);

  // Get stockPriceData
  const { data: stockPrices } = useStockPriceListQuery({});

  // Get Contracts data
  const { data: contracts } = useContractListForCustomerQuery({
    customerNumber: userState.selectedCustomerProfileNumber,
  });

  // Get Contract data.
  const { data: contract, isLoading: isContractLoading } =
    useContractForCustomerQuery({
      contractNumber: contractNumber || "",
    });

  // Get Commodity data
  const { data: commodities, isLoading: isCommodityListLoading } =
    useCommodityListForContractForCustomerQuery({
      contractNumber: contractNumber || "",
      customerNumber: userState.selectedCustomerProfileNumber,
    });

  // Get contract bookings data from the current contract
  const {
    data,
    isLoading: isContractBookingLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteContractsBookingListForContractForCustomerQuery({
    contractNumber: contractNumber || "",
    customerNumber: userState.selectedCustomerProfileNumber,
    filterContractBookingsBy: filterContractBookings || "",
  });
  const contractBookingPages = data?.pages;

  const currentContractStockPriceValue =
    calculateCurrentContractStockPriceValue({
      commodities: commodities || [],
      stockPriceList: stockPrices || [],
    });

  // First section details
  const firstSectionDetails = [
    {
      label: t("contracts.contractDetails.labels.contractStartLabel"),
      value:
        (contract?.startDate && contract.startDate) ||
        t("contracts.contractDetails.labels.noStartDate"),
    },
    {
      label: t("contracts.contractDetails.labels.contractEndLabel"),
      value:
        (contract?.endDate && contract.endDate) ||
        t("contracts.contractDetails.labels.noEndDate"),
    },
    {
      label: t("contracts.contractDetails.labels.orderTotalLabel"),
      value: t("common.currency", {
        formatParams: { value: { currency: contract?.currency } },
        value: contract?.orderTotal,
      }),
    },
    {
      label: t("contracts.contractDetails.labels.contractFeesLabel"),
      value: t("common.currency", {
        formatParams: { value: { currency: contract?.currency } },
        value: contract?.contractFees,
      }),
    },
    {
      label: t("contracts.contractDetails.labels.contributionAmountLabel"),
      value: t("common.currency", {
        formatParams: { value: { currency: contract?.currency } },
        value: contract?.contributionAmount,
      }),
    },
  ];

  // Second section details
  const secondSectionDetails = [
    {
      label: t("contracts.lastDepositLabel"),
      value: contract?.lastDepositMoneyAmount
        ? `${t("common.currency", {
            formatParams: { value: { currency: contract?.currency } },
            value: contract?.lastDepositMoneyAmount,
          })}

          (${contract?.lastDepositValueDate})`
        : "-",
    },
    {
      label: t("contracts.totalDepositsLabel"),
      value: contract?.totalDeposits
        ? t("common.currency", {
            formatParams: { value: { currency: contract?.currency } },
            value: contract?.totalDeposits,
          })
        : "-",
    },
    {
      label: t("contracts.totalPurchaseAmountLabel"),
      value: contract?.totalPurchaseAmount
        ? t("common.currency", {
            formatParams: { value: { currency: contract?.currency } },
            value: contract?.totalPurchaseAmount,
          })
        : "-",
    },
    {
      label: t("contracts.totalPaidFeesLabel"),
      value: contract?.totalPaidFees
        ? t("common.currency", {
            formatParams: { value: { currency: contract?.currency } },
            value: contract?.totalPaidFees,
          })
        : "-",
    },
    {
      label: t("contracts.contractDetails.labels.totalOtherFeesLabel"),
      value: contract?.totalOtherFees
        ? t("common.currency", {
            formatParams: { value: { currency: contract?.currency } },
            value: contract?.totalOtherFees,
          })
        : "-",
    },
    // "aktueller Wert" only in EUR
    {
      label: t("contracts.contractDetails.labels.totalCommodityValueLabel"),
      value: (
        <>
          {currentContractStockPriceValue > 0
            ? t("common.currency", {
                formatParams: { value: { currency: euroValueCurrency } },
                value: currentContractStockPriceValue,
              })
            : "-"}
          <InfoTooltip infoText={t("common.totalCommodityValueHelpText")} />
        </>
      ),
    },
  ];

  // Remove all contract bookings from the cache after unmounting the component,
  // except for the first 10 (the first page). This ensures a more efficient use of
  // the cache, as there can be a lot of contract bookings and that the list is
  // "retracted" again when the user leaves the page and returns. Similarly, we
  // do not need to make a new request to the backend for the first 10 and give
  // the end user a better UX feel as they do not have to wait for the initial data.
  useEffect(
    () => () => {
      contractBookingPages &&
        queryClient.setQueryData<PaginatedQueryCache<ContractBooking>>(
          [
            contractBookingKeys.contractsBookingList,
            contractNumber,
            filterContractBookings,
          ],
          (data) =>
            data
              ? {
                  pageParams: data.pageParams.slice(0, 1),
                  pages: data.pages.slice(0, 1),
                }
              : undefined,
        );
    },
    [],
  );

  useEffect(() => {
    selectedCommodity !== undefined &&
      fetchCommodityBookings({
        ...selectedCommodity,
        contractNumber: contractNumber,
        fetchMore: false,
      });
  }, [selectedCommodity]);

  if (isContractLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <CircularProgress style={{ color: theme.palette.primary.main }} />
      </Box>
    );
  }

  if (!contract) {
    // Handle the case where there is no current contract, perhaps redirect back or show a message.
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Typography color="error" align="center">
          {t("common.somethingWentWrong")}
        </Typography>
      </Box>
    );
  }

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case ContractDetailTabChoice.DETAILS:
        return (
          <Box className={cx(classes.content)}>
            <TwoSectionsCard
              title={t("contracts.contractDetails.distributorTitle")}
              leftSectionDetails={firstSectionDetails}
              rightSectionDetails={secondSectionDetails}
              sx={{ mb: 4 }}
            />

            <Card>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                wrap="nowrap"
              >
                <Grid item>
                  <Typography
                    variant="h2"
                    color="textColorDark"
                    sx={{ m: 2, mb: 2 }}
                  >
                    {t("contracts.contractDetails.turnoverLabel")}
                  </Typography>
                </Grid>

                <Grid item sx={{ height: "100%", overflow: "hidden" }}>
                  <WidgetActionTabs
                    onTabSwitch={(tabValue) =>
                      setFilterContractBookings(tabValue)
                    }
                    tabs={[
                      {
                        label: t("contracts.bookings.filter.all"),
                        value: "",
                      },
                      {
                        label: t("contracts.bookings.filter.deposits"),
                        value: BookingType.EZ,
                      },
                      {
                        label: t("contracts.bookings.filter.purchases"),
                        value: BookingType.RK,
                      },
                      {
                        label: t("contracts.bookings.filter.storageFees"),
                        value: BookingType.LG,
                      },
                    ]}
                  />
                </Grid>
              </Grid>

              <ContractBookingList
                contractBookingPages={contractBookingPages}
                contracts={contracts || []}
                isLoading={isContractBookingLoading}
              />

              {hasNextPage && (
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    color="primary"
                    onClick={() => fetchNextPage()}
                    size="small"
                    sx={{ m: 0.5, textTransform: "none" }}
                    variant="text"
                  >
                    {t("contracts.contractDetails.loadMoreButton")}
                    <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                  </Button>
                </CardActions>
              )}
            </Card>
          </Box>
        );
      case ContractDetailTabChoice.COMMODITIES:
        return (
          <CommodityCard
            commodities={commodities}
            commodityBookings={bookingsPerCommodity}
            isLoading={isCommodityListLoading}
            loadMoreTransactions={(commodityUnitCombination) =>
              fetchCommodityBookings({
                ...commodityUnitCombination,
                contractNumber: contractNumber,
                fetchMore: true,
              })
            }
            showHeader
            selectedCommodity={selectedCommodity}
            setSelectedCommodity={(selectedCommodity) => {
              selectedCommodity === undefined && resetBookingsPerCommodity();
              setSelectedCommodity(selectedCommodity);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Seo title={`${contractNumber}`} />
      <Box {...props}>
        <LayoutContentHeader
          breadcrumbIcon={
            <Icon sx={{ mr: 0.5 }} fontSize="inherit">
              library_books
            </Icon>
          }
          headerTitle={contract.productLabel}
          showBreadcrumb
        />

        <Tabs
          value={selectedTab}
          onChange={(_event, newValue) => setSelectedTab(newValue)}
        >
          <Tab
            value="details"
            label={t("contracts.contractDetails.customerTitle")}
          />
          <Tab value="commodities" label={t("contracts.commodities")} />
        </Tabs>

        <Box className={cx(classes.content)}>{renderSelectedTab()}</Box>
      </Box>
    </>
  );
};

export default ContractDetailPage;
