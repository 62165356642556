import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Box, BoxProps, Typography, useTheme } from "@mui/material";

import { paths } from "src/paths";
import { ContractPhase, DistributorContract } from "src/types/contract";
import { getContractStatusColor } from "src/utils/contract";
import { useUser } from "src/services/contexts/User/useUser";
import { useContractListForDistributorQuery } from "src/services/serverState/contract/useContractListForDistributorQuery";
import DataTable from "src/components/DataTable/DataTable";
import {
  CellType,
  Row,
  TableHeader,
} from "src/components/DataTable/DataTable.types";
import { useTableState } from "src/components/DataTable/useTableState";
import LayoutContentHeader from "src/components/LayoutContentHeader/LayoutContentHeader";
import { Seo } from "src/components/Seo";

interface DistributorContractsPageProps extends BoxProps {}

export const DistributorContractsPage: FC<DistributorContractsPageProps> = ({
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userState, userGetters } = useUser();
  const navigate = useNavigate();
  const distributorId = userState.user?.activeDistributorProfile?.distributorId;
  const {
    currentTablePage,
    debouncedSearchValue,
    handleOrderChange,
    order,
    searchValue,
    setCurrentTablePage,
    setSearchValue,
  } = useTableState();

  const tableHeaders: TableHeader[] = [
    {
      id: "productLabel",
      label: t("contracts.distributorContracts.name"),
      width: 200,
    },
    {
      id: "acquisitionDate",
      label: t("contracts.distributorContracts.acquired"),
      width: 100,
    },
    {
      id: "totalPurchaseAmount",
      label: t("contracts.distributorContracts.currentOrderTotal"),
      width: 150,
    },
    {
      id: "lastDepositValueDate",
      label: t("contracts.distributorContracts.lastPayIn"),
      width: 150,
    },
    {
      id: "totalPaidFees",
      label: t("contracts.distributorContracts.feesPaid"),
      width: 150,
    },
    {
      id: "phase",
      label: t("contracts.distributorContracts.phase"),
      width: 150,
    },
    {
      id: "depositRate",
      label: t("contracts.distributorContracts.depositRate"),
      width: 150,
    },
  ];

  const {
    data: contractsData,
    isLoading,
    isError,
  } = useContractListForDistributorQuery({
    distributorId,
    includeNested: userGetters.distributorIsDistributionCompany || false,
    order,
    page: currentTablePage,
    searchValue: debouncedSearchValue,
  });

  const { totalCount, results: contracts } = contractsData || {};

  const getPhaseLabel = (contract: DistributorContract) =>
    contract.phase in ContractPhase &&
    t(`contracts.contractPhase.${contract.phase}`);

  const rows: Row[] =
    contracts?.map((contract: DistributorContract) => ({
      cells: [
        {
          cellType: CellType.TITLE,
          subtitle: contract.customerLabel,
          title: contract.productLabel,
        },
        {
          cellType: CellType.TEXT,
          text: contract.acquisitionDate && contract.acquisitionDate,
        },
        {
          cellType: CellType.MONEY_RATIO,
          currency: contract.currency,
          currentAmount: contract.totalPurchaseAmount,
          targetAmount: contract.orderTotal,
        },
        {
          amount: contract.lastDepositMoneyAmount,
          cellType: CellType.TRANSACTION,
          currency: contract.currency,
          date: contract.lastDepositValueDate && contract.lastDepositValueDate,
        },
        {
          cellType: CellType.MONEY_RATIO,
          currency: contract.currency,
          currentAmount: contract.totalPaidFees,
          targetAmount: contract.contractFees,
        },
        {
          cellType: CellType.CHIP,
          color: getContractStatusColor(contract.status, theme),
          text: getPhaseLabel(contract),
        },
        {
          cellType: CellType.PROGRESS,
          progress: contract.depositRate,
        },
      ],
      id: contract.contractNumber,
    })) || [];

  return (
    <>
      <Seo title={t("contracts.navigationTitleDistributors")} />
      <Box display="flex" flexDirection="column" flexGrow={1} {...props}>
        <LayoutContentHeader
          headerTitle={t("contracts.navigationTitleDistributors")}
        />

        {isError && (
          <Box display="flex" justifyContent="center">
            <Typography color="error">
              {t("common.somethingWentWrong")}
            </Typography>
          </Box>
        )}

        {!isError && (
          <DataTable
            currentPage={currentTablePage}
            headers={tableHeaders}
            loading={isLoading}
            onOrderChange={handleOrderChange}
            onPageChange={setCurrentTablePage}
            onSearchValueChange={setSearchValue}
            order={order}
            rows={rows}
            searchLabel={t("contracts.distributorContracts.searchContracts")}
            searchValue={searchValue}
            totalResults={totalCount}
            OnTableRowClick={(row) => navigate(`${paths.contracts}/${row}`)}
          />
        )}
      </Box>
    </>
  );
};

export default DistributorContractsPage;
