import { useTranslation } from "react-i18next";

import { useUser } from "src/services/contexts/User/useUser";
import PublicLayout from "src/layouts/PublicLayout/PublicLayout";
import RequestPasswordForm from "src/components/Forms/RequestPasswordForm/RequestPasswordForm";
import { Seo } from "src/components/Seo";

export const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const { userActions } = useUser();

  return (
    <>
      <Seo title={t("auth.forgotPassword")} />
      <PublicLayout title={t("auth.forgotPassword")}>
        <RequestPasswordForm
          description={t("auth.resetPasswordHint")}
          buttonText={t("auth.resetPassword")}
          submitHandler={(email) => userActions.forgotPassword({ email })}
        />
      </PublicLayout>
    </>
  );
};

export default ForgotPasswordPage;
