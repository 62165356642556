import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, BoxProps, Typography } from "@mui/material";

import { paths } from "src/paths";
import { useUser } from "src/services/contexts/User/useUser";
import { useCustomerListQuery } from "src/services/serverState/customer/useCustomerListQuery";
import DataTable from "src/components/DataTable/DataTable";
import {
  CellType,
  Row,
  TableHeader,
} from "src/components/DataTable/DataTable.types";
import { useTableState } from "src/components/DataTable/useTableState";
import LayoutContentHeader from "src/components/LayoutContentHeader/LayoutContentHeader";
import { Seo } from "src/components/Seo";

interface DistributorContractsPageProps extends BoxProps {}

export const DistributorCustomersPage: FC<DistributorContractsPageProps> = ({
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userState, userGetters } = useUser();
  const distributorId = userState.user?.activeDistributorProfile?.distributorId;
  const {
    currentTablePage,
    debouncedSearchValue,
    handleOrderChange,
    order,
    searchValue,
    setCurrentTablePage,
    setSearchValue,
  } = useTableState();

  const tableHeaders: TableHeader[] = [
    {
      id: "customerLabel",
      label: t("customers.distributorCustomers.name"),
      width: 180,
    },
    {
      id: "street",
      label: t("customers.distributorCustomers.street"),
      width: 150,
    },
    {
      id: "zipCode",
      label: t("customers.distributorCustomers.postalcode"),
      width: 100,
    },
    {
      id: "town",
      label: t("customers.distributorCustomers.location"),
      width: 100,
    },
    {
      id: "email",
      label: t("customers.distributorCustomers.email"),
      width: 150,
    },
    {
      id: "phone",
      label: t("customers.distributorCustomers.telephone"),
      width: 150,
    },
    {
      id: "depositRate",
      label: t("customers.distributorCustomers.depositRate"),
      width: 150,
    },
  ];

  const {
    data: customersData,
    isLoading,
    isError,
  } = useCustomerListQuery({
    distributorId,
    includeNested: userGetters.distributorIsDistributionCompany || false,
    order,
    page: currentTablePage,
    searchValue: debouncedSearchValue,
  });

  const { totalCount, results: customers } = customersData || {};

  const rows: Row[] =
    customers?.map((customer) => ({
      cells: [
        {
          cellType: CellType.TITLE,
          subtitle: customer.distributorContracts.join(", "),
          title: customer.customerLabel,
        },
        {
          cellType: CellType.TEXT,
          text: customer.street,
        },
        {
          cellType: CellType.TEXT,
          text: customer.zipCode,
        },
        {
          cellType: CellType.TEXT,
          text: customer.town,
        },
        {
          cellType: CellType.TEXT,
          text: customer.email,
        },
        {
          cellType: CellType.TEXT,
          text: customer.phone,
        },
        {
          cellType: CellType.PROGRESS,
          progress: customer.depositRate || 0,
        },
      ],
      id: customer.customerNumber,
    })) || [];

  return (
    <>
      <Seo title={t("customers.navigationTitle")} />
      <Box display="flex" flexDirection="column" flexGrow={1} {...props}>
        <LayoutContentHeader headerTitle={t("customers.navigationTitle")} />

        {isError && (
          <Box display="flex" justifyContent="center">
            <Typography color="error">
              {t("common.somethingWentWrong")}
            </Typography>
          </Box>
        )}

        {!isError && (
          <DataTable
            currentPage={currentTablePage}
            headers={tableHeaders}
            loading={isLoading}
            onOrderChange={handleOrderChange}
            onPageChange={setCurrentTablePage}
            onSearchValueChange={setSearchValue}
            OnTableRowClick={(row) => navigate(`${paths.customers}/${row}`)}
            order={order}
            rows={rows}
            searchLabel={t("customers.searchCustomers")}
            searchValue={searchValue}
            totalResults={totalCount}
          />
        )}
      </Box>
    </>
  );
};
