import { AXI } from "../api/axi";

export interface SignInRequestProps {
  email: string;
  password: string;
}

export interface SetPasswordRequestProps {
  password: string;
  token: string;
}

export interface SetPasswordErrors {
  password?: string[];
  token?: string[];
}

export interface SetRequestPasswordErrors {
  email?: string[];
}

export const meRequest = async () => AXI.get("/me/");

export const signInRequest = ({ email, password }: SignInRequestProps) =>
  AXI.post("/login/", { email, password });

export const logoutRequest = async () => AXI.post("/logout/");

export const setPasswordRequest = async (
  password: string,
  temporaryToken: string,
) =>
  AXI.post("/set-password/", { password, token: temporaryToken }).then(
    (res) => res.data,
  );
export const setUserSettingsRequest = async ({
  selectedCurrency,
}: {
  selectedCurrency: string;
}) => AXI.put("/me/", { selectedCurrency }).then((res) => res.data);

export const updatePasswordRequest = async (
  password: string,
  temporaryToken: string,
) =>
  AXI.post("/update-password/", { password, token: temporaryToken }).then(
    (res) => res.data,
  );

export const forgotPasswordRequest = async (email: string) =>
  AXI.post("/forgot-password/", { email }).then((res) => res.data);

export const inviteUserRequest = async (email: string) =>
  AXI.post("/invite-user/", { email }).then((res) => res.data);

export const ssoSignInRequest = (token: string) =>
  AXI.post("/login/sso/", {
    token,
  });
