import { type FC, useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { useRoutes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { wrapUseRoutes } from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";

import i18n from "src/locales/i18nConfig";
import { routes } from "src/pages/routes";
import { createTheme, PaletteMode } from "src/theme";
import { AppVariant } from "src/types/config";
import { queryClient } from "src/services/serverState/queryClient";

const useSentryRoutes = wrapUseRoutes(useRoutes);

import { UserConsumer } from "src/services/contexts/User/UserConsumer";
import { UserProvider } from "src/services/contexts/User/UserProvider";

import { COOKIE_BOT_ID, IS_DEVELOPMENT_MODE } from "../../env";

const App: FC = () => {
  const routeComponents = useSentryRoutes(routes);

  // The colors and all the other theme options can be overwritten here, so that a White-Labeling is possible.
  // add palette Options to "palette" and use "paletteMode" "custom" to create a custom CD.

  // The theme for the "customer app"
  const customerTheme = createTheme({
    paletteMode: PaletteMode.customerBaseTheme,
    responsiveFontSizes: true,
  });

  // The theme for the "distributor app"
  const distributorTheme = createTheme({
    paletteMode: PaletteMode.distributorBaseTheme,
    responsiveFontSizes: true,
  });

  // Embed cookiebot
  useEffect(() => {
    // Do not apply cookiebot for local development
    if (IS_DEVELOPMENT_MODE) {
      return;
    }
    const script = document.createElement("script");

    script.id = "Cookiebot";
    script.src = "https://consent.cookiebot.com/uc.js";
    script.type = "text/javascript";
    script.async = true;

    script.setAttribute("data-cbid", COOKIE_BOT_ID);
    script.setAttribute("data-blockingmode", "auto");

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <UserProvider>
          <UserConsumer>
            {(userConsumer) => {
              // Use the correct theme, depending on the role of the user. A user can be a distributor or a customer.
              const theme =
                userConsumer.userState.selectedApp === AppVariant.Distributor
                  ? distributorTheme
                  : customerTheme;

              return (
                <ThemeProvider theme={theme}>
                  <SnackbarProvider maxSnack={3}>
                    <CssBaseline />
                    {routeComponents}
                  </SnackbarProvider>
                </ThemeProvider>
              );
            }}
          </UserConsumer>
        </UserProvider>
      </I18nextProvider>
    </QueryClientProvider>
  );
};

export default App;
