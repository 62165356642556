import type { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormControlProps,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { useUser } from "src/services/contexts/User/useUser";

import { useStyles } from "./CustomerProfileSelection.styles";

interface CustomerProfileSelectionProps extends FormControlProps {}

export const CustomerProfileSelection: FC<CustomerProfileSelectionProps> = ({
  ...props
}) => {
  const { classes } = useStyles();
  const { userActions, userState } = useUser();
  const { t } = useTranslation();
  const customerProfiles = userState.user?.customerProfiles;

  const handleCustomerProfileChange = (customerProfileNumber: number) => {
    userActions.setCustomerProfileNumber(customerProfileNumber);
  };

  const selectedCustomerProfile = customerProfiles?.find(
    (customerProfile) =>
      customerProfile.customerNumber ===
      userState.selectedCustomerProfileNumber,
  );

  return (
    <FormControl className={classes.formControl} size="small" {...props}>
      <>
        <Typography sx={{ marginRight: 1 }}>
          {t("common.customerProfileLabel")}:
        </Typography>

        <Select
          className={classes.select}
          value={selectedCustomerProfile?.customerNumber}
          onChange={(event) =>
            handleCustomerProfileChange(Number(event.target.value))
          }
        >
          {customerProfiles?.map(({ customerNumber, customerLabel }) => (
            <MenuItem value={customerNumber} key={customerNumber}>
              {customerLabel}
            </MenuItem>
          ))}
        </Select>
      </>
    </FormControl>
  );
};

export default CustomerProfileSelection;
