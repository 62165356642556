import { type FC, type ReactNode, useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Box, BoxProps, Grid, Icon, Typography, useTheme } from "@mui/material";

import { defaultPaginationCount } from "src/constants";
import { paths } from "src/paths";
import {
  BookingType,
  ContractPhase,
  DistributorContract,
} from "src/types/contract";
import { getContractStatusColor } from "src/utils/contract";
import reverseName from "src/utils/reverseName";
import { useUser } from "src/services/contexts/User/useUser";
import { useCommodityListForDistributorQuery } from "src/services/serverState/commodity/useCommodityListForDistributorQuery";
import { useContractListForDistributorFromCustomerQuery } from "src/services/serverState/contract/useContractListForDistributorFromCustomerQuery";
import { useInfiniteContractsBookingListForDistributorQuery } from "src/services/serverState/contractBooking/useContractsBookingListForDistributorQuery";
import { useCustomerDetailQuery } from "src/services/serverState/customer/useCustomerDetailQuery";
import { CommodityCard } from "src/components/CommodityCard/CommodityCard";
import ContractBookingList from "src/components/ContractBookingList/ContractBookingList";
import ContractSummary from "src/components/ContractSummary/ContractSummary";
import InfoCard from "src/components/DataCards/InfoCard/InfoCard";
import DataTable from "src/components/DataTable/DataTable";
import {
  CellType,
  Row,
  TableHeader,
} from "src/components/DataTable/DataTable.types";
import { useTableState } from "src/components/DataTable/useTableState";
import LayoutContentHeader from "src/components/LayoutContentHeader/LayoutContentHeader";
import { Seo } from "src/components/Seo";
import WidgetWrapper from "src/components/WidgetWrapper/WidgetWrapper";

import { useStyles } from "./Distributor.CustomerDetails.styles";

interface DistributorCustomerDetailPageProps extends BoxProps {}

export const DistributorCustomerDetailPage: FC<
  DistributorCustomerDetailPageProps
> = ({ ...props }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { customerNumber } = useParams();
  const navigate = useNavigate();
  const { userState, userGetters } = useUser();
  const distributorId = userState.user?.activeDistributorProfile?.distributorId;

  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [filterContractBookings, setFilterContractBookings] = useState("");
  const { currentTablePage, handleOrderChange, order, setCurrentTablePage } =
    useTableState();

  // Get Customer data.
  const { data: customer, isLoading: isCustomerLoading } =
    useCustomerDetailQuery({
      customerNumber: customerNumber || "",
    });

  // Get Contracts from customer
  const { data: contractsData, isLoading: isContractsLoading } =
    useContractListForDistributorFromCustomerQuery({
      customerNumber: customerNumber || "",
      distributorId: distributorId || "",
      includeNested: userGetters.distributorIsDistributionCompany || false,
      order,
    });

  const { totalCount, results: contracts } = contractsData || {};

  // Get Commodity data from customer
  const { data: commodities, isLoading: isCommodityListLoading } =
    useCommodityListForDistributorQuery({
      customerNumber: customerNumber || "",
      distributorId: distributorId,
      includeNested: userGetters.distributorIsDistributionCompany || false,
    });

  // Get contract bookings data from all contracts from one customer (only the first fifteen).
  const {
    data: contractBookings,
    hasNextPage,
    fetchNextPage,
    isLoading: isContractBookingLoading,
  } = useInfiniteContractsBookingListForDistributorQuery({
    customerNumber: customerNumber,
    distributorId: distributorId,
    filterContractBookingsBy: filterContractBookings,
    includeNested: userGetters.distributorIsDistributionCompany || false,
  });

  const contractListBookingPages = contractBookings?.pages;

  // Get all currencies from the contracts.
  const currenciesFromContracts = [
    ...new Set(contracts?.map((contract) => contract.currency)),
  ].sort();

  const filteredContracts = useMemo(() => {
    if (contracts) {
      return contracts.filter(
        (contract) =>
          contract.currency ===
          (selectedCurrency === ""
            ? currenciesFromContracts[0]
            : selectedCurrency),
      );
    }
  }, [selectedCurrency, contracts]);

  const filteredCommodities = useMemo(() => {
    if (commodities) {
      return commodities.filter(
        (commodity) =>
          commodity.currency ===
          (selectedCurrency === ""
            ? currenciesFromContracts[0]
            : selectedCurrency),
      );
    }
  }, [selectedCurrency, commodities, contracts]);

  const customerData: { label: string; value: string | ReactNode }[] = [
    {
      label: t("distributors.customers.name"),
      value: reverseName(customer?.customerLabel) || "-",
    },
    {
      label: t("distributors.customers.customerNumber"),
      value: customer?.customerNumber || "-",
    },
    {
      label: t("distributors.customers.address"),
      value:
        `${customer?.street}, ${customer?.zipCode} ${customer?.town}` || "-",
    },
    {
      label: t("distributors.customers.phone"),
      value: (
        <Typography
          color={theme.palette.primary.dark}
          component="a"
          href={`tel:-${customer?.phone}`}
          sx={{ textDecoration: "none" }}
        >
          {customer?.phone || "-"}
        </Typography>
      ),
    },
    {
      label: t("distributors.customers.mail"),
      value: (
        <Typography
          color={theme.palette.primary.dark}
          component="a"
          href={`mailto:${customer?.email}`}
          sx={{ textDecoration: "none" }}
        >
          {customer?.email || "-"}
        </Typography>
      ),
    },
  ];

  const tableHeaders: TableHeader[] = [
    {
      id: "productLabel",
      label: t("contracts.distributorContracts.name"),
      width: 200,
    },
    {
      id: "totalPurchaseAmount",
      label: t("contracts.distributorContracts.currentOrderTotal"),
      width: 150,
    },
    {
      id: "lastDepositValueDate",
      label: t("contracts.distributorContracts.lastPayIn"),
      width: 150,
    },
    {
      id: "totalPaidFees",
      label: t("contracts.distributorContracts.feesPaid"),
      width: 150,
    },
    {
      id: "phase",
      label: t("contracts.distributorContracts.phase"),
      width: 150,
    },
    {
      id: "depositRate",
      label: t("contracts.distributorContracts.depositRate"),
      width: 150,
    },
  ];

  const getPhaseLabel = (contract: DistributorContract) =>
    contract.phase in ContractPhase &&
    t(`contracts.contractPhase.${contract.phase}`);

  const rows: Row[] =
    contracts?.map((contract: DistributorContract) => ({
      cells: [
        {
          cellType: CellType.TITLE,
          subtitle: contract.customerLabel,
          title: contract.productLabel,
        },
        {
          cellType: CellType.MONEY_RATIO,
          currency: contract.currency,
          currentAmount: contract.totalPurchaseAmount,
          targetAmount: contract.orderTotal,
        },
        {
          amount: contract.lastDepositMoneyAmount,
          cellType: CellType.TRANSACTION,
          currency: contract.currency,
          date: contract.lastDepositValueDate && contract.lastDepositValueDate,
        },
        {
          cellType: CellType.MONEY_RATIO,
          currency: contract.currency,
          currentAmount: contract.totalPaidFees,
          targetAmount: contract.contractFees,
        },
        {
          cellType: CellType.CHIP,
          color: getContractStatusColor(contract.status, theme),
          text: getPhaseLabel(contract),
        },
        {
          cellType: CellType.PROGRESS,
          progress: contract.depositRate,
        },
      ],
      id: contract.contractNumber,
    })) || [];

  return (
    <>
      <Seo title={`${customerNumber}`} />
      <Box {...props}>
        <LayoutContentHeader
          breadcrumbIcon={
            <Icon sx={{ mr: 0.5 }} fontSize="inherit">
              assignment_ind
            </Icon>
          }
          headerTitle={reverseName(customer?.customerLabel) || "-"}
          showBreadcrumb
        />

        <Box className={cx(classes.content)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5} lg={4}>
              <InfoCard
                alternativeBackground
                fields={customerData}
                isLoading={isCustomerLoading}
                title={t("distributors.customers.customerDetailHeader")}
              />
            </Grid>

            <Grid item xs={12} md={7} lg={8}>
              <WidgetWrapper
                sx={{ backgroundColor: theme.palette.secondary.main }}
                onActionSwitch={(tabValue) => {
                  setSelectedCurrency(tabValue);
                }}
                actionTabsChoices={
                  // If a customer has only one contract the currency tab should not be displayed.
                  currenciesFromContracts.length > 1
                    ? currenciesFromContracts.map((currency) => ({
                        label: currency,
                        value: currency,
                      }))
                    : undefined
                }
                headerText={t("dashboard.widgets.contractSummary.label")}
              >
                <ContractSummary
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    p: 2,
                  }}
                  spacing={4}
                  contracts={filteredContracts}
                  commodities={filteredCommodities}
                  isLoading={isContractsLoading}
                />
              </WidgetWrapper>
            </Grid>

            <Grid item xs={12}>
              <WidgetWrapper
                headerText={t("distributors.customers.contractsWidgetHeader")}
              >
                <DataTable
                  currentPage={currentTablePage}
                  dataTableHeight={50 + rows.length * 70} // Calculate height of table. Max height is the height of five Contracts
                  dataTableMaxHeight={400}
                  headers={tableHeaders}
                  loading={isContractsLoading}
                  onOrderChange={handleOrderChange}
                  onPageChange={setCurrentTablePage}
                  order={order}
                  renderPagination={
                    totalCount ? totalCount > defaultPaginationCount : true
                  }
                  rows={rows}
                  sx={{ marginBottom: 0 }}
                  totalResults={totalCount}
                  OnTableRowClick={(row) =>
                    navigate(`${paths.contracts}/${row}`)
                  }
                />
              </WidgetWrapper>
            </Grid>

            <Grid item xs={12}>
              <WidgetWrapper
                headerText={t("contracts.commodities")}
                sx={{ mb: 4 }}
              >
                <CommodityCard
                  isLoading={isCommodityListLoading}
                  commodities={commodities}
                  showPrices={false}
                />
              </WidgetWrapper>
            </Grid>

            <Grid item xs={12}>
              <WidgetWrapper
                onActionSwitch={(tabValue) =>
                  setFilterContractBookings(tabValue)
                }
                actionTabsChoices={[
                  { label: t("contracts.bookings.filter.all"), value: "" },
                  {
                    label: t("contracts.bookings.filter.deposits"),
                    value: BookingType.EZ,
                  },
                  {
                    label: t("contracts.bookings.filter.purchases"),
                    value: BookingType.RK,
                  },
                  {
                    label: t("contracts.bookings.filter.storageFees"),
                    value: BookingType.LG,
                  },
                ]}
                headerText={t("contracts.contractDetails.turnoverLabel")}
                sx={{ mb: 4 }}
              >
                <ContractBookingList
                  contractBookingPages={contractListBookingPages}
                  contracts={contracts || []}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  isLoading={isContractBookingLoading}
                  renderAllContracts
                />
              </WidgetWrapper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default DistributorCustomerDetailPage;
