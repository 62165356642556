import type { FC, ReactNode } from "react";

import Error404Page from "src/pages/Error/Error404Page/Error404Page";
import { AppVariant } from "src/types/config";
import { useUser } from "src/services/contexts/User/useUser";

interface RouteSwitchProps {
  customerAppPage: ReactNode;
  distributorAppPage: ReactNode;
}

/**
 * This component is a switch for the selected App. There are two apps. Customer and distributor.
 * If the current user is a customer, render the customer app.
 * If the current user is a distributor or a customer and a distributor, the distributor application is displayed initially.
 * The current user can change the app if the user has both roles.
 */
export const RouteSwitch: FC<RouteSwitchProps> = ({
  customerAppPage,
  distributorAppPage,
}) => {
  const { userState } = useUser();

  if (userState.selectedApp === AppVariant.Distributor) {
    return distributorAppPage !== false ? distributorAppPage : <Error404Page />;
  }

  return customerAppPage !== false ? customerAppPage : <Error404Page />;
};
