import type { FC, ReactNode } from "react";
import { useEffect } from "react";

import { useRouter } from "src/hooks/useRouter";
import { useUser } from "src/services/contexts/User/useUser";

interface PublicGuardProps {
  children: ReactNode;
}

/*
  A guard component that is used to wrap public pages like the login screen.
  It returns blank until the initial load is done and reroutes if the user is authenticated.
*/

export const PublicGuard: FC<PublicGuardProps> = (props) => {
  const { children } = props;

  const router = useRouter();
  const { userGetters, userState } = useUser();
  const isAuthenticated = userGetters.isAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      router.replace("/");
    }
  }, [router, isAuthenticated]);

  if (!userState.initialFetchDone || isAuthenticated) {
    return null;
  }

  return <>{children}</>;
};
