import { type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { matchPath, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import type { DrawerProps } from "@mui/material";
import { Box, Button, Typography } from "@mui/material";
import { Drawer, Stack } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useTheme } from "@mui/material/styles";

import { AppVariant } from "src/types/config";
import { useUser } from "src/services/contexts/User/useUser";
import GgLogo from "src/components/Icons/GgLogo";

import {
  getCustomerNavigationElements,
  getDistributorNavigationElements,
  NAVIGATION_DEFAULT_WIDTH,
} from "./Navigation.config";

import { useStyles } from "./Navigation.styles";

interface NavigationProps extends DrawerProps {
  children?: ReactNode;
  onClose: () => void;
  isOpen: boolean;
  navigationWidth: string;
  variant: "permanent" | "temporary";
}

export const Navigation: FC<NavigationProps> = ({
  isOpen,
  navigationWidth = NAVIGATION_DEFAULT_WIDTH,
  onClose,
  variant,
  ...props
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { userActions, userState } = useUser();
  const theme = useTheme();
  const selectedApp = userState.selectedApp;
  const navItemActiveColor =
    selectedApp === AppVariant.Distributor
      ? theme.palette.ternary.main
      : theme.palette.primary.main;
  const { classes, cx } = useStyles({ navigationWidth, navItemActiveColor });

  const NAVIGATION_ELEMENTS =
    userState.selectedApp === AppVariant.Distributor
      ? getDistributorNavigationElements()
      : getCustomerNavigationElements();

  return (
    <Drawer
      anchor="left"
      data-testid="navigation"
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        className: classes.navigationPaper,
      }}
      variant={variant}
      {...props}
    >
      <Stack>
        <Stack alignItems="center" sx={{ p: 4 }}>
          <GgLogo width={150} fillColor={theme.palette.secondary.main} />
        </Stack>

        <Stack
          component="nav"
          spacing={2}
          sx={{
            flexGrow: 1,
            px: 2,
          }}
        >
          {NAVIGATION_ELEMENTS.map((section, index) => (
            <Stack
              component="ul"
              key={index}
              spacing={0.5}
              className={classes.navSection}
            >
              {section.subheader && (
                <Box component="li">{section.subheader}</Box>
              )}

              {section.items.map((item) => {
                const isActive = matchPath(
                  `/${item.path}/*`,
                  location.pathname,
                );
                return (
                  <Link
                    onClick={onClose}
                    style={{ textDecoration: "none" }}
                    target={item.externalLink ? "_blank" : "_self"}
                    to={item.path}
                    key={item.path}
                  >
                    <Box
                      alignItems="center"
                      className={cx(
                        classes.navItem,
                        isActive && classes.navItemActive,
                      )}
                      display="flex"
                    >
                      <Icon
                        style={{
                          color:
                            isActive && selectedApp === AppVariant.Distributor
                              ? theme.palette.ternary.contrastText
                              : isActive &&
                                  selectedApp !== AppVariant.Distributor
                                ? theme.palette.primary.contrastText
                                : theme.palette.secondary.main,
                        }}
                      >
                        {item.icon}
                      </Icon>

                      <Typography
                        color={
                          isActive && selectedApp === AppVariant.Distributor
                            ? "ternary.contrastText"
                            : isActive && selectedApp !== AppVariant.Distributor
                              ? "primary.contrastText"
                              : "secondary"
                        }
                        component="span"
                        ml={1}
                        variant="h3"
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </Link>
                );
              })}
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Stack sx={{ p: 4 }}>
        <Box pb={4}>
          <Link
            className={classes.externalLinks}
            target="_blank"
            to={t("navigation.externalLinks.faq")}
          >
            <Typography variant="subtitle2">
              {t("navigation.externalLinkLabels.faq")}
            </Typography>
          </Link>

          <Link
            className={classes.externalLinks}
            target="_blank"
            to={t("navigation.externalLinks.privacyPolicy")}
          >
            <Typography variant="subtitle2">
              {t("navigation.externalLinkLabels.privacyPolicy")}
            </Typography>
          </Link>

          <Link
            className={classes.externalLinks}
            target="_blank"
            to={t("navigation.externalLinks.imprint")}
          >
            <Typography variant="subtitle2">
              {t("navigation.externalLinkLabels.imprint")}
            </Typography>
          </Link>

          <Link
            className={classes.externalLinks}
            target="_blank"
            to={t("navigation.externalLinks.termsAndCondition")}
          >
            <Typography variant="subtitle2">
              {t("navigation.externalLinkLabels.termsAndCondition")}
            </Typography>
          </Link>
        </Box>

        {userState.user?.isCustomer && userState.user.isDistributor && (
          <Button
            className={classes.switchButton}
            disableRipple
            onClick={userActions.switchSelectedApp}
            startIcon={<ChevronLeftIcon style={{ fontSize: "22" }} />}
            style={{ fontSize: "12px" }}
            sx={{
              "& .MuiButton-startIcon": { marginRight: "0px" },
            }}
          >
            {userState.selectedApp === AppVariant.Distributor
              ? t("navigation.switchToCustomerButton")
              : t("navigation.switchToDistributorButton")}
          </Button>
        )}

        <Button
          color="secondary"
          onClick={userActions.logoutUser}
          startIcon={<LogoutIcon />}
          style={{
            width: "max-content",
          }}
        >
          <Typography>{t("auth.logout")}</Typography>
        </Button>
      </Stack>
    </Drawer>
  );
};

export default Navigation;
