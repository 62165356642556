import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import type { Theme } from "@mui/material/styles/createTheme";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { paths } from "src/paths";
import GgLogo from "src/components/Icons/GgLogo";
import { Seo } from "src/components/Seo";

import { useStyles } from "./Error404Page.styles";

const Error404Page = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { classes } = useStyles();

  return (
    <>
      <Seo title={t("common.error404")} />
      <Box component="main">
        <Container maxWidth="lg">
          <Box className={classes.wrapper}>
            <GgLogo
              width={smUp ? 300 : 200}
              fillColor={theme.palette.quartaer.main}
            />

            <Typography
              align="center"
              component="h1"
              fontFamily="Lora"
              fontWeight="light"
              color={theme.palette.quartaer.main}
              fontSize={smUp ? "8rem" : "6rem"}
            >
              404
            </Typography>
            <Typography
              align="center"
              fontWeight="bold"
              color={theme.palette.quartaer.main}
              variant="h2"
              mt={0.5}
            >
              {t("error.pageNotFound")}
            </Typography>
            <Button
              size="large"
              sx={{ mt: 6, py: 2 }}
              variant="contained"
              onClick={() => navigate(paths.index)}
            >
              {t("error.toDashboard")}
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Error404Page;
