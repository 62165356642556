import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Box, BoxProps, Grid, Icon, useTheme } from "@mui/material";

import { paths } from "src/paths";
import { useUser } from "src/services/contexts/User/useUser";
import { useContractListForDistributorPartnerQuery } from "src/services/serverState/contract/useContractListForDistributorPartnerQuery";
import { useDistributorPartnerDetailQuery } from "src/services/serverState/distrubutorPartner/useDistributorPartnerDetailQuery";
import { useDistributorPartnerKeyFiguresQuery } from "src/services/serverState/distrubutorPartner/useDistributorPartnerKeyFiguresQuery";
import InfoCard from "src/components/DataCards/InfoCard/InfoCard";
import DataTable from "src/components/DataTable/DataTable";
import { useTableState } from "src/components/DataTable/useTableState";
import LayoutContentHeader from "src/components/LayoutContentHeader/LayoutContentHeader";
import { Seo } from "src/components/Seo";
import WidgetWrapper from "src/components/WidgetWrapper/WidgetWrapper";

import {
  getDistributorDetailsStructuredData,
  getKeyFiguresStructuredData,
  getTableHeaders,
  getTableRows,
} from "./dataMapping";

interface DistributorDistributorDetailPageProps extends BoxProps {}

export const DistributorDistributorDetailPage: FC<
  DistributorDistributorDetailPageProps
> = ({ ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { distributorId } = useParams(); // the id of the distributor currently viewing
  const { userState, userGetters } = useUser();
  const theme = useTheme();
  const ownDistributorId =
    userState.user?.activeDistributorProfile?.distributorId;

  const [keyFiguresPeriod, setKeyFiguresPeriod] = useState<string>("month");

  const {
    currentTablePage,
    debouncedSearchValue,
    order,
    handleOrderChange,
    setCurrentTablePage,
    setSearchValue,
    searchValue,
  } = useTableState();

  const {
    data: distributorContractsData,
    isLoading: contractsLoading,
    isError: contractsError,
  } = useContractListForDistributorPartnerQuery({
    distributorId: ownDistributorId,
    includeNested: true, // Show always the contracts from the partners. Clickable only if the user is a distribution company.
    order,
    page: currentTablePage,
    partnerId: Number(distributorId),
    searchValue: debouncedSearchValue,
  });

  const { data: detailsData, isLoading: detailsLoading } =
    useDistributorPartnerDetailQuery({
      distributorId: ownDistributorId,
      partnerId: Number(distributorId),
    });

  const { data: keyFiguresData, isLoading: keyFiguresLoading } =
    useDistributorPartnerKeyFiguresQuery({
      distributorId: ownDistributorId,
      partnerId: Number(distributorId),
      period: keyFiguresPeriod,
    });

  const { totalCount, results: contracts } = distributorContractsData || {};

  const distributorDetails = getDistributorDetailsStructuredData({
    data: detailsData,
    t,
    theme,
  });

  const keyFigures = getKeyFiguresStructuredData({ data: keyFiguresData, t });

  const tableHeaders = getTableHeaders(t);
  const rows = getTableRows({
    contracts,
    t,
    theme,
  });

  return (
    <>
      <Seo title={`${t("distributors.navigationTitle")} - ${distributorId}`} />
      <Box mb={4} {...props}>
        <LayoutContentHeader
          breadcrumbIcon={
            <Icon sx={{ mr: 0.5 }} fontSize="inherit">
              groups
            </Icon>
          }
          isLoading={detailsLoading}
          headerTitle={detailsData?.distributorLabel || ""}
          showBreadcrumb
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={4}>
            <InfoCard
              isLoading={detailsLoading}
              title={t("common.details")}
              fields={distributorDetails}
            />
          </Grid>

          <Grid item xs={12} md={7} lg={8}>
            <InfoCard
              actionTabsChoices={[
                {
                  label: t("common.currentMonth"),
                  value: "month",
                },
                {
                  label: t("common.currentYear"),
                  value: "year",
                },
              ]}
              displayVariant="horizontal"
              fields={keyFigures}
              isLoading={keyFiguresLoading}
              onActionTabSwitch={setKeyFiguresPeriod}
              title={t("common.summary")}
            />
          </Grid>

          <Grid item xs={12}>
            <WidgetWrapper
              headerText={t("common.contracts")}
              showDivider={false}
            >
              {!contractsError && (
                <DataTable
                  currentPage={currentTablePage}
                  dataTableHeight={
                    rows.length === 0 ? 120 : 50 + rows.length * 70
                  } // Calculate height of table. Max height is the height of five Contracts
                  dataTableMaxHeight={400}
                  headers={tableHeaders}
                  loading={contractsLoading}
                  onOrderChange={handleOrderChange}
                  onPageChange={setCurrentTablePage}
                  onSearchValueChange={setSearchValue}
                  order={order}
                  rows={rows}
                  searchLabel={t(
                    "contracts.distributorContracts.searchContracts",
                  )}
                  searchValue={searchValue}
                  totalResults={totalCount}
                  // Clickable only if the distributor is a distribution company.
                  {...(userGetters.distributorIsDistributionCompany && {
                    OnTableRowClick: (row) =>
                      navigate(`${paths.contracts}/${row}`),
                  })}
                  sx={{ marginBottom: 0 }}
                />
              )}
            </WidgetWrapper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DistributorDistributorDetailPage;
