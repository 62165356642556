import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";

import { useUser } from "src/services/contexts/User/useUser";
import PublicLayout from "src/layouts/PublicLayout/PublicLayout";
import { Seo } from "src/components/Seo";

export const SSOPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { userGetters, userState, userActions } = useUser();
  const isAuthenticated = userGetters.isAuthenticated();
  const loginFailed = userState.loginFailed;

  const token = useMemo(() => searchParams.get("token") || "", [searchParams]);

  useEffect(() => {
    userActions.signInUserSSO(token);
  }, [isAuthenticated, token]);

  return (
    <>
      <Seo title={t("sso.title")} />
      <PublicLayout title={t("sso.title")}>
        {!loginFailed ? (
          <Typography color="white">{t("sso.loading")}</Typography>
        ) : (
          <Typography color="white">{t("sso.error")}</Typography>
        )}
      </PublicLayout>
    </>
  );
};

export default SSOPage;
