/* eslint-disable sort-keys-fix/sort-keys-fix */
import type { FC, ReactNode } from "react";
import { Outlet, type RouteObject } from "react-router";

import { withAuthGuard } from "src/guards/AuthGuard/withAuthGuard";
import { withPublicGuard } from "src/guards/PublicGuard/withPublicGuard";
import Error404Page from "src/pages/Error/Error404Page/Error404Page";
import { paths } from "src/paths";
import DefaultLayout from "src/layouts/DefaultLayout/DefaultLayout";
import SiteNavigationLayout from "src/layouts/SiteNavigationLayout/SiteNavigationLayout";

import CustomerContractDetailPage from "./Customer.Contracts/Customer.ContractDetails/Customer.ContractDetails";
import CustomerContractsPage from "./Customer.Contracts/Customer.Contracts";
import CustomerDashboardPage from "./Customer.Dashboard/Customer.Dashboard";
import DistributorContractDetailPage from "./Distributor.Contracts/Distributor.ContractDetail/Distributor.ContractDetails";
import DistributorContractsPage from "./Distributor.Contracts/Distributor.Contracts";
import DistributorCustomerDetailPage from "./Distributor.Customers/Distributor.CustomerDetail/Distributor.CustomerDetails";
import { DistributorCustomersPage } from "./Distributor.Customers/Distributor.Customers";
import DistributorDashboardPage from "./Distributor.Dashboard/Distributor.Dashboard";
import DistributorDistributorDetailPage from "./Distributor.Distributors/Distributor.DistributorDetail/Distributor.DistributorDetail";
import { DistributorDistributorsPage } from "./Distributor.Distributors/Distributor.Distributors";
import DownloadsPage from "./Downloads/Downloads";
import LoginPage from "./Login/Login";
import ForgotPasswordPage from "./Password/ForgotPassword";
import SetPasswordPage from "./Password/SetPassword";
import UpdatePasswordPage from "./Password/UpdatePassword";
import SSOPage from "./SSO/SSO";
import WelcomePage from "./Welcome/Welcome";
import { RouteSwitch } from "./RouteSwitch";

interface RouteProps {
  children?: ReactNode;
}

export const ProtectedRoute: FC<RouteProps> = withAuthGuard(
  ({ children }) => children,
);

export const PublicRoute: FC<RouteProps> = withPublicGuard(
  ({ children }) => children,
);

// eslint-disable-next-line react-refresh/only-export-components
export const routes: RouteObject[] = [
  {
    path: paths.index,
    element: (
      <ProtectedRoute>
        <SiteNavigationLayout>
          <Outlet />
        </SiteNavigationLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <RouteSwitch
            distributorAppPage={<DistributorDashboardPage />}
            customerAppPage={<CustomerDashboardPage />}
          />
        ),
      },
      {
        path: paths.contracts,
        element: (
          <RouteSwitch
            distributorAppPage={<DistributorContractsPage />}
            customerAppPage={<CustomerContractsPage />}
          />
        ),
      },
      {
        path: paths.contractDetails,
        element: (
          <RouteSwitch
            distributorAppPage={<DistributorContractDetailPage />}
            customerAppPage={<CustomerContractDetailPage />}
          />
        ),
      },
      {
        path: paths.customers,
        element: (
          <RouteSwitch
            distributorAppPage={<DistributorCustomersPage />}
            customerAppPage={false}
          />
        ),
      },
      {
        path: paths.customerDetail,
        element: (
          <RouteSwitch
            distributorAppPage={<DistributorCustomerDetailPage />}
            customerAppPage={false}
          />
        ),
      },
      {
        path: paths.distributors,
        element: (
          <RouteSwitch
            distributorAppPage={<DistributorDistributorsPage />}
            customerAppPage={false}
          />
        ),
      },
      {
        path: paths.distributorDetail,
        element: (
          <RouteSwitch
            distributorAppPage={<DistributorDistributorDetailPage />}
            customerAppPage={false}
          />
        ),
      },
      {
        path: paths.downloadsWithSubFolder,
        element: (
          <RouteSwitch
            distributorAppPage={<DownloadsPage />}
            customerAppPage={false}
          />
        ),
      },
      {
        element: <Error404Page />,
        path: "404",
      },
      {
        element: <Error404Page />,
        path: "*",
      },
    ],
  },

  {
    element: (
      <DefaultLayout>
        <Outlet />
      </DefaultLayout>
    ),
    children: [
      {
        element: (
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        ),
        path: paths.login,
      },
      {
        element: (
          <PublicRoute>
            <SSOPage />
          </PublicRoute>
        ),
        path: paths.sso,
      },
      {
        element: (
          <PublicRoute>
            <SetPasswordPage />
          </PublicRoute>
        ),
        path: paths.setPassword,
      },
      {
        element: (
          <PublicRoute>
            <UpdatePasswordPage />
          </PublicRoute>
        ),
        path: paths.updatePassword,
      },
      {
        element: (
          <PublicRoute>
            <ForgotPasswordPage />
          </PublicRoute>
        ),
        path: paths.forgotPassword,
      },
      {
        element: (
          <PublicRoute>
            <WelcomePage />
          </PublicRoute>
        ),
        path: paths.welcome,
      },
    ],
  },
];
