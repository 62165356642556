import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";

import { AppVariant } from "src/types/config";
import { User } from "src/types/user";

import * as api from "../../api/userApi";
import {
  SetPasswordErrors,
  SetPasswordRequestProps,
  SetRequestPasswordErrors,
  SignInRequestProps,
} from "../../api/userApi";
import { queryClient } from "../../serverState/queryClient";

import { UserContext } from "./UserContext";

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const { i18n, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [user, setUser] = useState<User | undefined | null>();
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [selectedApp, setSelectedApp] = useState(AppVariant.Customer);
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
  const [selectedCustomerProfileNumber, setSelectedCustomerProfileNumber] =
    useState<number | undefined>(undefined);
  const [setPasswordSuccess, setSetPasswordSuccess] = useState(false);
  const [setPasswordErrors, setSetPasswordErrors] = useState<
    SetPasswordErrors | undefined | null
  >();
  const [setRequestPasswordSuccess, setSetRequestPasswordSuccess] =
    useState(false);
  const [setRequestPasswordErrors, setSetRequestPasswordErrors] = useState<
    SetRequestPasswordErrors | undefined | null
  >();
  const [meCallSucceeded, setMeCallSucceeded] = useState(false);

  const selectedCustomerProfile = user?.customerProfiles.find(
    ({ customerNumber }) => customerNumber === selectedCustomerProfileNumber,
  );

  const customerDistributorContact = selectedCustomerProfile?.lastDistributor;

  const fetchUser = useCallback(async () => {
    try {
      const { data } = await api.meRequest();
      localStorage.setItem("csrftoken", data.csrftoken);

      if (i18n.language !== data.selectedLanguage) {
        i18n.changeLanguage(data.selectedLanguage);
      }

      const isDistributor = !!data.distributorProfiles.length;
      const isCustomer = !!data.customerProfiles.length;
      const activeDistributorProfile = isDistributor
        ? data.distributorProfiles[0]
        : undefined;

      setUser({
        ...data,
        activeDistributorProfile,
        isCustomer,
        isDistributor,
      });

      setSelectedCurrency(data.selectedCurrency);

      if (isCustomer) {
        setSelectedCustomerProfileNumber(
          data.customerProfiles[0]?.customerNumber,
        );
      }

      setSelectedApp(
        isCustomer && !isDistributor
          ? AppVariant.Customer
          : AppVariant.Distributor,
      );
      setMeCallSucceeded(true);
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status !== 403) {
        enqueueSnackbar(t("common.somethingWentWrong"), { variant: "error" });
      }
    } finally {
      setInitialFetchDone(true);
    }
  }, [enqueueSnackbar, i18n, t]);

  const signInUserSSO = async (token: string) => {
    try {
      const { data } = await api.ssoSignInRequest(token);
      localStorage.setItem("csrftoken", data.csrftoken);
      setIsLoggedOut(false);
      setLoginFailed(false);
      fetchUser();
    } catch (error) {
      console.log(error);
      setLoginFailed(true);
    }
  };

  const signInUser = async ({ email, password }: SignInRequestProps) => {
    try {
      const { data } = await api.signInRequest({ email, password });
      localStorage.setItem("csrftoken", data.csrftoken);
      setIsLoggedOut(false);
      setLoginFailed(false);
      fetchUser();
    } catch (error) {
      console.error(error);
      setLoginFailed(true);
    }
  };

  const logoutUser = async () => {
    try {
      await api.logoutRequest();
      setUser(null);
      setSelectedApp(AppVariant.Customer);
      setIsLoggedOut(true);
      queryClient.removeQueries();
    } catch (error) {
      console.error(error);
    }
  };

  const setCurrency = async (currencyCode: string) => {
    try {
      await api.setUserSettingsRequest({ selectedCurrency: currencyCode });
      setSelectedCurrency(currencyCode);
    } catch (error) {
      console.error(error);
    }
  };

  const setCustomerProfileNumber = (customerProfileNumber: number) => {
    setSelectedCustomerProfileNumber(customerProfileNumber);
  };

  const setPassword = async ({ password, token }: SetPasswordRequestProps) => {
    try {
      await api.setPasswordRequest(password, token);
      setSetPasswordErrors(null);
      setSetPasswordSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setSetPasswordErrors({
          password: error?.response?.data?.password,
          token: error?.response?.data?.token,
        });
      }
      setSetPasswordSuccess(false);
    }
  };

  const updatePassword = async ({
    password,
    token,
  }: SetPasswordRequestProps) => {
    try {
      await api.updatePasswordRequest(password, token);
      setSetPasswordErrors(null);
      setSetPasswordSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setSetPasswordErrors({
          password: error?.response?.data?.password,
          token: error?.response?.data?.token,
        });
      }
      setSetPasswordSuccess(false);
    }
  };

  const forgotPassword = async ({ email }: { email: string }) => {
    try {
      await api.forgotPasswordRequest(email);
      setSetRequestPasswordErrors(null);
      setSetRequestPasswordSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setSetRequestPasswordErrors({
          email: error?.response?.data?.email,
        });
      }
      setSetRequestPasswordSuccess(false);
    }
  };

  const inviteUser = async ({ email }: { email: string }) => {
    try {
      await api.inviteUserRequest(email);
      setSetRequestPasswordErrors(null);
      setSetRequestPasswordSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setSetRequestPasswordErrors({
          email: error?.response?.data?.email,
        });
      }
      setSetRequestPasswordSuccess(false);
    }
  };

  const switchSelectedApp = () => {
    setSelectedApp(
      selectedApp === AppVariant.Customer
        ? AppVariant.Distributor
        : AppVariant.Customer,
    );
    navigate("/");
  };

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const isAuthenticated = () =>
    !!(user && meCallSucceeded && initialFetchDone && !isLoggedOut);
  const distributorIsDistributionCompany =
    user?.activeDistributorProfile?.distributorIsDistributionCompany || false;
  const distributorId = user?.activeDistributorProfile?.distributorId;
  const customerProfilesCount = user?.customerProfiles?.length;

  if (!initialFetchDone) {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        userActions: {
          fetchUser,
          forgotPassword,
          inviteUser,
          logoutUser,
          setCurrency,
          setCustomerProfileNumber,
          setPassword,
          signInUser,
          signInUserSSO,
          switchSelectedApp,
          updatePassword,
        },
        userGetters: {
          customerDistributorContact,
          customerProfilesCount,
          distributorId,
          distributorIsDistributionCompany,
          isAuthenticated,
        },
        userState: {
          initialFetchDone,
          loginFailed,
          selectedApp,
          selectedCurrency,
          selectedCustomerProfileNumber,
          setPasswordErrors,
          setPasswordSuccess,
          setRequestPasswordErrors,
          setRequestPasswordSuccess,
          user,
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
