import { createContext } from "react";

import { AppVariant } from "src/types/config";
import { DistributorContact } from "src/types/contract";
import { User } from "src/types/user";
import {
  SetPasswordErrors,
  SetPasswordRequestProps,
  SetRequestPasswordErrors,
  SignInRequestProps,
} from "src/services/api/userApi";

export interface UserContextValue {
  userActions: {
    switchSelectedApp: () => void;
    fetchUser: () => void;
    inviteUser: ({ email }: { email: string }) => Promise<void>;
    logoutUser: () => void;
    signInUser: ({ email, password }: SignInRequestProps) => void;
    signInUserSSO: (token: string) => void;
    setCurrency: (currencyCode: string) => void;
    setCustomerProfileNumber: (customerProfile: number) => void;
    setPassword: ({
      password,
      token,
    }: SetPasswordRequestProps) => Promise<void>;
    updatePassword: ({
      password,
      token,
    }: SetPasswordRequestProps) => Promise<void>;
    forgotPassword: ({ email }: { email: string }) => Promise<void>;
  };

  userGetters: {
    customerProfilesCount: number | undefined;
    customerDistributorContact: DistributorContact | undefined;
    distributorId: number | undefined;
    distributorIsDistributionCompany: boolean | undefined;
    isAuthenticated: () => boolean | undefined;
  };

  userState: {
    initialFetchDone: boolean;
    loginFailed: boolean;
    selectedApp: AppVariant;
    selectedCurrency: string;
    selectedCustomerProfileNumber: number | undefined;
    setPasswordErrors: SetPasswordErrors | undefined | null;
    setPasswordSuccess: boolean;
    setRequestPasswordErrors: SetRequestPasswordErrors | undefined | null;
    setRequestPasswordSuccess: boolean;
    user: User | undefined | null;
  };
}

// Initial placeholder values for the UserContext
export const UserContext = createContext<UserContextValue>({
  userActions: {
    fetchUser: async () => {},
    forgotPassword: async () => {},
    inviteUser: async () => {},
    logoutUser: async () => {},
    setCurrency: async () => {},
    setCustomerProfileNumber: async () => {},
    setPassword: async () => {},
    signInUser: async () => {},
    signInUserSSO: async () => {},
    switchSelectedApp: () => {},
    updatePassword: async () => {},
  },
  userGetters: {
    customerDistributorContact: undefined,
    customerProfilesCount: undefined,
    distributorId: undefined,
    distributorIsDistributionCompany: false,
    isAuthenticated: () => false,
  },
  userState: {
    initialFetchDone: false,
    loginFailed: false,
    selectedApp: AppVariant.Customer,
    selectedCurrency: "EUR",
    selectedCustomerProfileNumber: undefined,
    setPasswordErrors: undefined,
    setPasswordSuccess: false,
    setRequestPasswordErrors: undefined,
    setRequestPasswordSuccess: false,
    user: null,
  },
});
