import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { paths } from "src/paths";
import { ValidationRules } from "src/utils/formValidation";
import { SignInRequestProps } from "src/services/api/userApi";
import { useUser } from "src/services/contexts/User/useUser";
import { LoadingButton } from "src/components/LoadingButton/LoadingButton";

interface LoginFormState {
  email: string;
  password: string;
}

const initialValues: LoginFormState = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: ValidationRules.email(),
  password: ValidationRules.password(),
});

interface LoginFormProps {}

export const LoginForm: FC<LoginFormProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const { userGetters, userState, userActions } = useUser();

  const isAuthenticated = userGetters.isAuthenticated();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSignIn = async (
    signInValues: SignInRequestProps,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const email = signInValues.email;
    const password = signInValues.password;

    await userActions.signInUser({
      email,
      password,
    });

    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (signInValues, { setSubmitting }) =>
      handleSignIn(signInValues, setSubmitting),
    validateOnChange: true,
    validationSchema,
  });

  /**
   * @BUG
   *
   *  Title: Mui browser auto fill bug
   *
   * Mui 5 has a bug in its current version.
   * The TextField components are mutilated when the browser autofills the components.
   * So some adjustments have to be made.
   * The solution in the issues are not possible because the shrink is set to true and is not removable,
   * so if the input is cleared the shrink does not go back. This is the reason for the custom solution.
   *
   * @todo Track the issue and fix it when mui has a version update with a stable solution.
   *
   * https://github.com/mui/material-ui/issues/36448
   * https://github.com/mui/material-ui/issues/33519
   *
   */
  const [isAutofillActiveEmail, setAutofillActiveEmail] = useState(false);
  const [isAutofillActivePassword, setAutofillActivePassword] = useState(false);
  useEffect(() => {
    const autofill = setTimeout(() => {
      const isAutofill = document.querySelector("input:-webkit-autofill");
      setAutofillActivePassword(!!isAutofill);
      setAutofillActiveEmail(!!isAutofill);
    }, 600);

    return () => clearTimeout(autofill);
  }, []);
  /**
   *  Mui browser auto fill Bug END.
   */

  if (!userState.initialFetchDone || isAuthenticated) {
    return null;
  }

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            error={
              !!(
                formik.touched.email &&
                formik.errors.email &&
                formik.values.email
              )
            }
            fullWidth
            helperText={formik.touched.email && formik.errors.email}
            InputLabelProps={{
              shrink: isAutofillActiveEmail || !!formik.values.email, // check comment "Mui browser auto fill bug"
            }}
            label={t("auth.email")}
            name="email"
            onBlur={() => {
              setAutofillActiveEmail(false); // check comment "Mui browser auto fill bug"
              formik.handleBlur;
            }}
            onChange={formik.handleChange}
            onFocus={() => setAutofillActiveEmail(true)} // check comment "Mui browser auto fill bug"
            placeholder={t("auth.placeholderMail")}
            type="email"
            value={formik.values.email}
            variant="filled"
          />

          <TextField
            error={
              !!(
                formik.touched.password &&
                formik.errors.password &&
                formik.values.password
              )
            }
            fullWidth
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? (
                      <VisibilityOff color="secondary" />
                    ) : (
                      <Visibility color="secondary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: isAutofillActivePassword || !!formik.values.password, // check comment "Mui browser auto fill bug"
            }}
            label={t("auth.password")}
            name="password"
            onBlur={() => {
              setAutofillActivePassword(false); // check comment "Mui browser auto fill bug"
              formik.handleBlur;
            }}
            onChange={formik.handleChange}
            onFocus={() => setAutofillActivePassword(true)} // check comment "Mui browser auto fill bug"
            placeholder={t("auth.placeholderPassword")}
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            variant="filled"
          />
        </Stack>

        {userState.loginFailed && (
          <Typography color={theme.palette.common.white} marginTop={2}>
            {t("auth.loginFailed")}
          </Typography>
        )}

        <LoadingButton
          loading={formik.isSubmitting}
          fullWidth
          size="large"
          sx={{ mt: 2, py: 2 }}
          type="submit"
          variant="contained"
        >
          {t("auth.login")}
        </LoadingButton>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Link style={{ textDecoration: "none" }} to={paths.forgotPassword}>
            <Typography variant="h6" color={"secondary"}>
              {t("auth.forgotPassword")}
            </Typography>
          </Link>
        </Box>
      </form>
    </>
  );
};

export default LoginForm;
