import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";

import { paths } from "src/paths";
import { useRouter } from "src/hooks/useRouter";
import { useUser } from "src/services/contexts/User/useUser";

interface AuthGuardProps {
  children: ReactNode;
}

/*
  A guard that is used to protect routes from being visible when the user
  is not logged in. It reroutes to the login page and returns blank until the
  initial check is done.
*/

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const { userGetters, userState } = useUser();

  const isAuthenticated = userGetters.isAuthenticated();
  const [checked, setChecked] = useState<boolean>(false);

  const check = useCallback(() => {
    if (!isAuthenticated) {
      router.replace(paths.login);
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, router]);

  // Check if userState changes
  useEffect(() => {
    check();
  }, [check, userState]);

  if (!checked || !isAuthenticated) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};
